<template>
  <div>
    <v-card :loading='loading'>
      <v-card-title>
        <router-link
          class='font-weight-medium text-decoration-none me-2'
          :to="{ name: 'Course-set'}"
        >
          <v-tooltip
            color='#212121'
            top
          >
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                color='primary'
                v-bind='attrs'
                icon
                v-on='on'
              >
                <v-icon>{{ icons.mdiArrowLeftBold }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('back') }}</span>
          </v-tooltip>
        </router-link>
        {{ courseSetResult.courseset_name }}
        <StatusBlock class='ms-2' :status=String(courseSetResult.courseset_active_id) />
        <v-spacer></v-spacer>
        <v-btn color='primary' @click='isAddNewDetailCourseSet = true'>
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          {{ $t('AddListCourseSet') }}
        </v-btn>
      </v-card-title>
      <v-row class='px-5'>
        <v-col
          cols='12'
          md='6'
          lg='4'
        >
          <span class='font-weight-semibold'>{{ $t('code') }} :</span>
          {{ courseSetResult.courseset_id }}
        </v-col>
        <v-col
          cols='12'
          md='6'
          lg='4'
        >
          <span class='font-weight-semibold'> {{ $t('price') }} : </span>
          {{ courseSetResult.courseset_price }}
        </v-col>
      </v-row>
      <v-data-table
        class='mt-3'
        :headers='columns'
        :items='dataTableList'
        dense
        :options.sync='options'
        :loading='loading'
        hide-default-footer
        disable-sort
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.id`]='{ index }'>
          {{ index + 1 }}
        </template>
        <template v-slot:[`item.courseset_map_active_id`]='{ item }'>
          <StatusBlock :status='String(item.courseset_map_active_id)' />
        </template>
        <template v-slot:[`item.courseset_map_price`]='{ item }'>
          {{ item.courseset_map_price | formatPrice }}
        </template>
        <template v-slot:[`item.actions`]='{ item }'>

          <v-tooltip
            color='#212121'
            top
          >
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                icon
                color='primary'
                @click='dataEdit= item;isEditDetailCourseSet = true'
                v-bind='attrs'
                v-on='on'
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            {{ $t('edit') }}
          </v-tooltip>

          <v-tooltip
            color='#212121'
            top
          >
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                icon
                color='primary'
                @click='dataEdit = item;isUpdateStatus = true'
                v-bind='attrs'
                v-on='on'
              >
                <v-icon>
                  {{ item.courseset_map_active_id == 1 ? icons.mdiClose : icons.mdiCheck
                  }}
                </v-icon>
              </v-btn>
            </template>
            {{ item.courseset_map_active_id == 1 ? $t('suspend') : $t('normal') }}
          </v-tooltip>
          <v-tooltip
            color='#212121'
            top
          >
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                icon
                color='primary'
                v-bind='attrs'
                v-on='on'
                @click='removeId = item.courseset_map_id;isRemove = true'
              >
                <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
              </v-btn>
            </template>
            {{ $t('delete') }}
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <AddNewDetailCourseSet v-model='isAddNewDetailCourseSet'
                           :course-group-list='courseGroupList'
                           :course-list='dataCourseList'
                           @changeCourseGroup='onChangeCourseGroup'
                           @onAdd='getCourseSetById();getCourseSetList();' />
    <EditCourseDetailSet v-model='isEditDetailCourseSet'
                         :dataEdit='dataEdit'
                         :course-group-list='courseGroupList'
                         :course-list='dataCourseList'
                         @changeCourseGroup='onChangeCourseGroup'
                         @onUpdate='getCourseSetById();getCourseSetList();'
    />
    <RemoveDetailCourseSet v-model='isRemove' @onRemove='getCourseSetById();getCourseSetList();'
                           :remove-id='removeId' />

    <DetailCourseSetUpdateStatus v-model='isUpdateStatus' :data-edit='dataEdit'
                                 @onUpdate='getCourseSetById();getCourseSetList();' />
  </div>
</template>

<script>
import { ref, watch, onMounted } from '@vue/composition-api'
import courseSet from '@/api/courseSet/courseSet'
import {
  mdiPlus,
  mdiPencilOutline,
  mdiCheck,
  mdiDeleteOutline,
  mdiClose,
  mdiArrowLeftBold,
} from '@mdi/js'
import AddNewDetailCourseSet from './AddNewDetailCourseSet'
import courseGroup from '@/api/courseManagement/courseGroup'
import courseList from '@/api/courseManagement/courseList'
import { i18n } from '@/plugins/i18n'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import EditCourseDetailSet from './EditCourseDetailSet'
import RemoveDetailCourseSet from './RemoveDetailCourseSet'
import DetailCourseSetUpdateStatus from './DetailCourseSetUpdateStatus.vue'
import { formatPrice } from '@/plugins/filters'

export default {
  props: {},
  filters: {
    formatPrice,
  },
  components: {
    AddNewDetailCourseSet,
    StatusBlock,
    EditCourseDetailSet,
    RemoveDetailCourseSet,
    DetailCourseSetUpdateStatus,
  },
  setup(_, { root, emit }) {
    const courseSetResult = ref({})
    const isAddNewDetailCourseSet = ref(false)
    const courseGroupList = ref([])
    const courseGroupId = ref('')
    const options = ref({
      itemsPerPage: -1,
    })
    const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
    const search = ref('')
    const dataCourseList = ref([])
    const dataTableList = ref([])
    const isEditDetailCourseSet = ref(false)
    const dataEdit = ref({})
    const columns = ref([
      {
        text: '#',
        align: 'start',
        value: 'id',
        width: 70,
      },
      {
        text: i18n.t('code'),
        value: 'course_id',
      },
      {
        text: i18n.t('name'),
        value: 'course_name',
      },
      {
        text: i18n.t('amountUnit'),
        value: 'course_amount_unit',
        align: 'end',
      },
      {
        text: i18n.t('price'),
        value: 'courseset_map_price',
        align: 'end',
      },
      {
        text: i18n.t('status'),
        value: 'courseset_map_active_id',
        align: 'center',
      },
      {
        text: i18n.t('option'),
        value: 'actions',
        sortable: false,
        align: 'center',
      },
    ])
    const loading = ref(false)
    const isRemove = ref(false)
    const isUpdateStatus = ref(false)
    const removeId = ref('')
    onMounted(() => {
      getCourseSetList()
      getCourseSetById()
      getCourseGroup()
    })

    const getCourseGroup = () => {
      courseGroup.courseGroupList({
        searchtext: '',
        coursegroup_status_id: 1,
      }).then(res => {
        courseGroupList.value = res
        courseGroupList.value.unshift({
          coursegroup_id: '',
          coursegroup_name: i18n.t('all'),
        })
        getCourseList()
      })
    }

    const getCourseList = () => {
      loading.value = true
      courseList.courseListGet({
        searchtext: search.value,
        coursegroup_id: courseGroupId.value,
        course_status_id: '1',
        active_page: 1,
        per_page: '0',

      }).then(res => {
        dataCourseList.value = res.data
        loading.value = false
      }).catch(err => {
        console.log('err course list : ', err)
        loading.value = false
      })
    }

    const getCourseSetList = () => {
      loading.value = true
      courseSet.getCourseInSetList({
        courseset_id: root.$route.params.id,
        lang: i18n.locale,
      }).then(res => {
        dataTableList.value = res.data
        loading.value = false
      }).catch(err => {
        loading.value = false
        console.log(err)
      })
    }

    const getCourseSetById = () => {
      courseSet.getCourseSetById(root.$route.params.id).then(res => {
        courseSetResult.value = res.data
      }).catch(err => {
        console.log(err)
      })
    }

    const onChangeCourseGroup = (value) => {
      courseGroupId.value = value
      getCourseList()
    }

    return {
      courseSetResult,
      isAddNewDetailCourseSet,
      courseGroupList,
      onChangeCourseGroup,
      getCourseSetById,
      dataCourseList,
      columns,
      dataTableList,
      options,
      footer,
      dataEdit,
      getCourseSetList,
      loading,
      isEditDetailCourseSet,
      isRemove,
      removeId,
      isUpdateStatus,
      icons: {
        mdiPlus,
        mdiPencilOutline, mdiCheck, mdiDeleteOutline,
        mdiArrowLeftBold,
        mdiClose,
      },
    }
  },

}
</script>

<style scoped>

</style>
