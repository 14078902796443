<template>
  <div>
    <v-dialog v-model='isAddNewDetailCourseSet' persistent max-width='500'>
      <v-card>
        <v-card-title>
          {{ $t('AddListCourseSet') }}
        </v-card-title>
        <v-form
          ref='formAddDetailCourseSet'
          @submit.prevent='createDetailCourseSet'
        >
          <v-card-text>
            <!--            <v-autocomplete
                          v-model='coursegroup_id'
                          outlined
                          dense
                          :label="$t('course_category')"
                          :items='courseGroupList'
                          :no-data-text="$t('no_information')"
                          class='mt-5'
                          hide-details='auto'
                          item-text='coursegroup_name'
                          item-value='coursegroup_id'
                        ></v-autocomplete>-->
            <v-autocomplete
              v-model='course_id_pri'
              outlined
              dense
              :label="$t('course_list')"
              :rules='[required]'
              :items='courseList'
              hide-details='auto'
              return-object
              class='mt-5'
              :no-data-text="$t('no_information')"
              item-text='course_name'
              item-value='course_id_pri'
            >
              <template v-slot:item='{ item }'>
                <span v-if='item.course_id' class='me-1'>
                  ({{ item.course_id }})
                </span>
                <span>
                  {{ item.course_name }}
                </span>
              </template>
              <template v-slot:selection='{ item }'>
                <span v-if='item.course_id' class='me-1'>
                  ({{ item.course_id }})
                </span>
                <span>
                  {{ item.course_name }}
                </span>
              </template>

            </v-autocomplete>
            <v-text-field
              v-model.trim='courseset_map_price'
              hide-details='auto'
              class='mt-5'
              type='number'
              :label="$t('price')"
              outlined
              :rules='[required]'
              dense
            >
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color='primary'
              type='submit'
              :loading='loading'
              :disabled='loading'
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color='secondary'
              @click="$emit('update:isAddNewDetailCourseSet',false)"
              outlined
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required, textValidation } from '@/@core/utils/validation'
import courseSet from '@/api/courseSet/courseSet'
import store from '@/store'

export default {
  model: {
    prop: 'isAddNewDetailCourseSet',
    event: 'update:isAddNewDetailCourseSet',
  },
  props: {
    isAddNewDetailCourseSet: {
      type: Boolean,
      default: false,
    },
    courseList: {
      type: Array,
      default: () => [],
    },
    courseGroupList: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  setup(props, { root, emit }) {
    const courseset_map_price = ref('')
    const coursegroup_id = ref('')
    const course_id_pri = ref('')
    const formAddDetailCourseSet = ref(false)
    const loading = ref(false)

    const createDetailCourseSet = () => {
      const isFormValid = formAddDetailCourseSet.value.validate()
      if (!isFormValid) return
      loading.value = true
      courseSet.courseInSetAdd({
        courseset_id: root.$route.params.id,
        course_id_pri: course_id_pri.value.course_id_pri,
        courseset_map_price: courseset_map_price.value,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:isAddNewDetailCourseSet', false)
        emit('onAdd')
        loading.value = false
        clearData()
      }).catch(err => {
        console.log('error : ', err)
        loading.value = false
      })
    }

    const clearData = () => {
      courseset_map_price.value = ''
      coursegroup_id.value = ''
      course_id_pri.value = ''
    }

    watch(coursegroup_id, value => {
      course_id_pri.value = ''
      emit('changeCourseGroup', value)
    })

    watch(course_id_pri, value => {
      courseset_map_price.value = value.course_price
    })

    return {
      course_id_pri,
      coursegroup_id,
      courseset_map_price,
      loading,
      createDetailCourseSet,
      formAddDetailCourseSet,
      required,
      textValidation,
    }
  },

}
</script>
