var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_c('router-link',{staticClass:"font-weight-medium text-decoration-none me-2",attrs:{"to":{ name: 'Course-set'}}},[_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiArrowLeftBold))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('back')))])])],1),_vm._v(" "+_vm._s(_vm.courseSetResult.courseset_name)+" "),_c('StatusBlock',{staticClass:"ms-2",attrs:{"status":String(_vm.courseSetResult.courseset_active_id)}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.isAddNewDetailCourseSet = true}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_vm._v(" "+_vm._s(_vm.$t('AddListCourseSet'))+" ")],1)],1),_c('v-row',{staticClass:"px-5"},[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('span',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(_vm.$t('code'))+" :")]),_vm._v(" "+_vm._s(_vm.courseSetResult.courseset_id)+" ")]),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('span',{staticClass:"font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.$t('price'))+" : ")]),_vm._v(" "+_vm._s(_vm.courseSetResult.courseset_price)+" ")])],1),_c('v-data-table',{staticClass:"mt-3",attrs:{"headers":_vm.columns,"items":_vm.dataTableList,"dense":"","options":_vm.options,"loading":_vm.loading,"hide-default-footer":"","disable-sort":"","loading-text":_vm.$t('data_loading'),"no-data-text":_vm.$t('no_information')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.courseset_map_active_id",fn:function(ref){
var item = ref.item;
return [_c('StatusBlock',{attrs:{"status":String(item.courseset_map_active_id)}})]}},{key:"item.courseset_map_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.courseset_map_price))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.dataEdit= item;_vm.isEditDetailCourseSet = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPencilOutline))])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")]),_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.dataEdit = item;_vm.isUpdateStatus = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(item.courseset_map_active_id == 1 ? _vm.icons.mdiClose : _vm.icons.mdiCheck)+" ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(item.courseset_map_active_id == 1 ? _vm.$t('suspend') : _vm.$t('normal'))+" ")]),_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.removeId = item.courseset_map_id;_vm.isRemove = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteOutline))])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('delete'))+" ")])]}}],null,true)})],1),_c('AddNewDetailCourseSet',{attrs:{"course-group-list":_vm.courseGroupList,"course-list":_vm.dataCourseList},on:{"changeCourseGroup":_vm.onChangeCourseGroup,"onAdd":function($event){_vm.getCourseSetById();_vm.getCourseSetList();}},model:{value:(_vm.isAddNewDetailCourseSet),callback:function ($$v) {_vm.isAddNewDetailCourseSet=$$v},expression:"isAddNewDetailCourseSet"}}),_c('EditCourseDetailSet',{attrs:{"dataEdit":_vm.dataEdit,"course-group-list":_vm.courseGroupList,"course-list":_vm.dataCourseList},on:{"changeCourseGroup":_vm.onChangeCourseGroup,"onUpdate":function($event){_vm.getCourseSetById();_vm.getCourseSetList();}},model:{value:(_vm.isEditDetailCourseSet),callback:function ($$v) {_vm.isEditDetailCourseSet=$$v},expression:"isEditDetailCourseSet"}}),_c('RemoveDetailCourseSet',{attrs:{"remove-id":_vm.removeId},on:{"onRemove":function($event){_vm.getCourseSetById();_vm.getCourseSetList();}},model:{value:(_vm.isRemove),callback:function ($$v) {_vm.isRemove=$$v},expression:"isRemove"}}),_c('DetailCourseSetUpdateStatus',{attrs:{"data-edit":_vm.dataEdit},on:{"onUpdate":function($event){_vm.getCourseSetById();_vm.getCourseSetList();}},model:{value:(_vm.isUpdateStatus),callback:function ($$v) {_vm.isUpdateStatus=$$v},expression:"isUpdateStatus"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }